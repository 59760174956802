/** @jsx jsx */
import { jsx, Button, Box } from "theme-ui"
import { graphql } from "gatsby"
import { useState } from "react"
import { trackCustomEvent, OutboundLink } from "gatsby-plugin-google-analytics"

import { FaFacebookF, FaTwitter, FaInstagram } from "react-icons/fa"
import Img from "gatsby-image"
import ShareButtons from "../components/share-buttons"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Container from "../components/container"

const Page = ({ data }) => {
  const [lista, setLista] = useState(0)

  const handleClick = (name, index) => {
    setLista(lista === index ? 0 : index)

    trackCustomEvent({
      category: `Simbolo ${name}`,
      action: "Click",
    })
  }

  const Logo = ({ name, img, index }) => (
    <Button
      sx={{ width: `150px`, p: 0, mx: 1, bg: `rgba(0,0,0,0.0)`, flex: 1 }}
      aria-label={name}
      onClick={() => handleClick(name, index)}
    >
      <Img alt={name} fluid={img} />
    </Button>
  )

  const Viewer = ({ name, website, docId, img, links }) => (
    <section
      sx={{
        mt: 4,
        mx: 2,
        p: [2, 4],
        textAlign: `center`,
        borderStyle: `solid`,
        borderWidth: `15px 4px 4px 4px`,
        borderColor: `primary`,
        borderRadius: `15px`,
        boxShadow: `-10px 10px 80px rgba(0,0,0,.2)`,
      }}
    >
      <div
        sx={{
          width: `150px`,
          mx: `auto`,
          mb: 3,
          transition: `all ease 2s`,
          "&:hover": { transform: `scale(1.3)` },
        }}
      >
        <Img alt={name} fluid={img} />
      </div>
      <OutboundLink
        href={`${website}/?utm_medium=referral&utm_source=arturolorenzoni`}
      >
        <h2 sx={{ textTransform: `uppercase`, letterSpacing: 2, mb: 4 }}>
          {name}
        </h2>
      </OutboundLink>
      {links.map((item, index) => (
        <div
          sx={{
            display: `flex`,
            mb: 4,
            justifyContent: `center`,
            alignItems: `center`,
          }}
          key={index}
        >
          {item.label && (
            <Box
              sx={{
                textTransform: `uppercase`,
                fontFamily: `body`,
                fontSize: 3,
                letterSpacing: 3,
                fontWeight: 500,
              }}
            >
              {item.label}
            </Box>
          )}
          {item.facebook && (
            <OutboundLink
              href={`https://www.facebook.com/${item.facebook}/?utm_medium=referral&utm_source=arturolorenzoni`}
            >
              <FaFacebookF
                size={30}
                sx={{ variant: `button.social` }}
                aria-label={`Facebook ${name}`}
              />
            </OutboundLink>
          )}
          {item.twitter && (
            <OutboundLink
              href={`https://www.twitter.com/${item.twitter}/?utm_medium=referral&utm_source=arturolorenzoni`}
            >
              <FaTwitter
                sx={{ variant: `button.social` }}
                size={30}
                aria-label={`Twitter ${name}`}
              />
            </OutboundLink>
          )}
          {item.instagram && (
            <OutboundLink
              href={`https://www.instagram.com/${item.instagram}/?utm_medium=referral&utm_source=arturolorenzoni`}
            >
              <FaInstagram
                size={30}
                sx={{ variant: `button.social` }}
                aria-label={`Instagram ${name}`}
              />
            </OutboundLink>
          )}
        </div>
      ))}
      {docId && (
        <a
          sx={{ variant: `button.default`, bg: `primary`, mx: `auto`, mt: 3 }}
          href={`/assets/pdf/${docId}.pdf`}
          onClick={() =>
            trackCustomEvent({
              category: `Programma ${name}`,
              action: "Download",
            })
          }
        >
          Programma Elettorale
        </a>
      )}
    </section>
  )
  return (
    <Layout title="La Coalizione" liste={false}>
      <SEO
        title="La Coalizione"
        description="La Coalizione a sostegno della candidatura di Arturo Lorenzoni alla Presidenza della Regione Veneto"
      />
      <Container sx={{ maxWidth: 680, textAlign: `center`, mb: 4 }}>
        <p sx={{ px: [4, null] }}>
          Fai click su un simbolo per visualizzare tutte le informazioni
        </p>
        <div
          sx={{
            display: `flex`,
            flexWrap: `wrap`,
            alignItems: `center`,
            justifyContent: `space-around`,
          }}
        >
          <Logo
            name="Il Veneto che vogliamo"
            img={data.lista01.childImageSharp.fluid}
            index={1}
          />
          <Logo
            name="Partito Democratico"
            img={data.lista02.childImageSharp.fluid}
            index={2}
          />
          <Logo
            name="+Veneto In Europa Volt"
            img={data.lista03.childImageSharp.fluid}
            index={3}
          />
          <Logo
            name="Europa Verde"
            img={data.lista04.childImageSharp.fluid}
            index={4}
          />
          <Logo
            name="Sanca Veneta"
            img={data.lista05.childImageSharp.fluid}
            index={5}
          />
        </div>
        {lista === 1 && (
          <Viewer
            name="Il Veneto che Vogliamo"
            img={data.lista01.childImageSharp.fluid}
            docId="programma-vcv-2020"
            links={[
              {
                facebook: `venetochevogliamo`,
                twitter: `venetocvogliamo`,
                instagram: `ilvenetochevogliamo`,
              },
            ]}
            website="https://www.venetochevogliamo.it"
          />
        )}
        {lista === 2 && (
          <Viewer
            name="Partito Democratico"
            img={data.lista02.childImageSharp.fluid}
            docId="programma-pd-veneto-2020"
            links={[
              {
                facebook: `PDveneto`,
                twitter: `PD_Veneto`,
                instagram: `pd_veneto`,
              },
            ]}
            website="https://www.partitodemocraticoveneto.com"
          />
        )}
        {lista === 3 && (
          <Viewer
            name="+Veneto In Europa"
            img={data.lista03.childImageSharp.fluid}
            website="https://www.piuvenetoineuropa.eu"
            links={[
              {
                label: `Volt`,
                facebook: `VoltVeneto`,
                instagram: `voltveneto`,
              },
              {
                label: `+Europa`,
                instagram: `piueuropa.veneto`,
              },
            ]}
          />
        )}
        {lista === 4 && (
          <Viewer
            name="Europa Verde"
            img={data.lista04.childImageSharp.fluid}
            website="https://www.verdiveneto.org"
            links={[
              {
                facebook: `verdiveneto`,
                twitter: `VerdiVeneto`,
                instagram: `verdiveneti`,
              },
            ]}
          />
        )}
        {lista === 5 && (
          <Viewer
            name="Sanca Veneta"
            img={data.lista05.childImageSharp.fluid}
            website="http://www.sancaveneta.org"
            links={[
              {
                facebook: `sancaveneta`,
                twitter: `sancaveneta`,
                instagram: `sancaveneta`,
              },
            ]}
          />
        )}
        <ShareButtons
          title="La Coalizione"
          url="https://www.arturolorenzoni.it/coalizione/"
          sx={{ px: [4, null] }}
        />
      </Container>
    </Layout>
  )
}

export default Page

export const data = graphql`
  query {
    lista01: file(relativePath: { eq: "liste/vcv.png" }) {
      childImageSharp {
        fluid(maxWidth: 150) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    lista02: file(relativePath: { eq: "liste/pd.png" }) {
      childImageSharp {
        fluid(maxWidth: 150) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    lista03: file(relativePath: { eq: "liste/+veneto.png" }) {
      childImageSharp {
        fluid(maxWidth: 150) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    lista04: file(relativePath: { eq: "liste/verdi.png" }) {
      childImageSharp {
        fluid(maxWidth: 150) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    lista05: file(relativePath: { eq: "liste/sanca.png" }) {
      childImageSharp {
        fluid(maxWidth: 150) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
